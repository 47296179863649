import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic03 from '../images/pic03.jpg'
import { useStaticQuery, graphql } from "gatsby"
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const Main = props => {
  // const [aboutRaw, setAboutRaw] = useState('')
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulSpaContent {
          nodes {
            aboutRichText {
              raw
            }
            background {
              raw
            }
            resume {
              file {
                url
              }
            }
          }
        }
        allContentfulWorkSample {
          nodes {
            workTitle
            description {
              raw
            }
            thumbnail {
              file {
                url
              }
            }
          }
          totalCount
        }
      }
    `
  )
  let close = (
    <div
      role="button"
      tabIndex="0"
      className="close"
      aria-label="Close"
      onKeyDown={() => {
        props.onCloseArticle()
      }}
      onClick={() => {
        props.onCloseArticle()
      }}
    ></div>
  )

  function paragraphClass(node) {
    const className = 'odd';
    //alternate logic for 'odd' | 'even'
    return className;
  }

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
    },
  }

  const aboutRaw = JSON.parse(data.allContentfulSpaContent.nodes[0].aboutRichText.raw)
  const backgroundRaw = JSON.parse(data.allContentfulSpaContent.nodes[0].background.raw)
  const workSamplesArray = data.allContentfulWorkSample.nodes
  // console.log(data.allContentfulSpaContent.nodes[0].resume.file.url)

  const workSampleList = workSamplesArray.map((sample, id)=>{
    // console.log(sample)
    return(
      <div className="workSample" key={id}>
        <h3>{sample.workTitle}</h3>
        <span className="image main">
          <img src={sample.thumbnail.file.url} alt="" />
        </span>
        {documentToReactComponents(JSON.parse(sample.description.raw), options)}
      </div>
    )
  })

  return (
    <div
      ref={props.setWrapperRef}
      id="main"
      style={props.timeout ? { display: 'flex' } : { display: 'none' }}
    >
      <article
        id="intro"
        className={`${props.article === 'intro' ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">Background</h2>
        <span className="image main">
          <img src={pic01} alt="" />
        </span>
        {documentToReactComponents(backgroundRaw, options)}
        {close}
      </article>

      <article
        id="work"
        className={`${props.article === 'work' ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">Recent Work</h2>

        {workSampleList}

        {close}
      </article>

      <article
        id="about"
        className={`${props.article === 'about' ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">About</h2>
        <span className="image main">
          <img src={pic03} alt="" />
        </span>
        {documentToReactComponents(aboutRaw, options)}
        {close}
      </article>

      <article
        id="contact"
        className={`${props.article === 'contact' ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">Contact</h2>
        <h3>Ryan O'Reilly</h3>
        {/* <form method="post" action="#">
          <div className="field half first">
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="name" />
          </div>
          <div className="field half">
            <label htmlFor="email">Email</label>
            <input type="text" name="email" id="email" />
          </div>
          <div className="field">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" rows="4"></textarea>
          </div>
          <ul className="actions">
            <li>
              <input type="submit" value="Send Message" className="special" />
            </li>
            <li>
              <input type="reset" value="Reset" />
            </li>
          </ul>
        </form> */}
        <ul className="icons">
          <li>
            <a
              href="mailto:oreilly.ryan@gmail.com"
              className="icon fa-envelope-o">
              <span className="label">Email</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/ryanoreilly/"
              target="_blank"
              rel="noreferrer"
              className="icon fa-linkedin">
              <span className="label">LinkedIn</span>
            </a>
          </li>
          <li>
            <a
              href="https://github.com/rporeilly"
              target="_blank"
              rel="noreferrer"
              className="icon fa-github"
            >
              <span className="label">GitHub</span>
            </a>
          </li>
        </ul>
        <p>
        <a
          href={data.allContentfulSpaContent.nodes[0].resume.file.url}
          target="_blank"
          rel="noreferrer"
          >Download resumé</a>
        </p>
        {close}
      </article>
    </div>
  )
}


Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
