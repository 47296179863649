import PropTypes from 'prop-types'
import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

const Header = props => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulSpaContent {
          nodes {
            intro {
              intro
            }
            landingSubTitle
            landingTitle
          }
        }
      }
    `
  )
  // console.log(data.allContentfulSpaContent.nodes[0])
  return (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    {/* <div className="logo">
      <span className="icon fa-diamond"></span>
    </div> */}
    <div className="content">
      <div className="inner">
        <h1>{data.allContentfulSpaContent.nodes[0].landingTitle}</h1>
        <h3>{data.allContentfulSpaContent.nodes[0].landingSubTitle}</h3>
        <p>
          {data.allContentfulSpaContent.nodes[0].intro.intro}
        </p>
      </div>
    </div>
    <nav>
      <ul>
      <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Background
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Work
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
  )
}

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
